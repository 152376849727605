import { ToastrService } from "ngx-toastr";
import { ColDef, ColGroupDef, ColumnApi, GridApi, GridOptions } from "ag-grid-community";
import { CustomTooltipComponent } from "./auxillary-components/custom-tooltip.component";
import { IUserProfile, CisJobStateEnum, DetailsDataType, TicketStatus } from "../npr-request.model";
import { ApiService } from "../services";
import { LoadingScreenService } from "./loading-screen.service";
import { PreprocessDateTime } from "../utility/view-field-mapping";
import { BaseComponent } from "./base.component";
import { ModalService } from "./modal.service";
import { GenerateCISJobUrl, GenerateCISTaskUrl } from "../utility/common-helper";
import { SubscriptionRendererComponent } from "../quota/approver-review/subscription-renderer.component";
import { ServiceTypeRendererComponent } from "./az-mapping-modal/service-type-renderer.component";
import { StatusTooltipComponent } from "../quota/request-status/status-tooltip.component";
import { Ticket } from "../generated-models";
import { agDateColumnFilter, cacheFilterStatsLocal, setupFilterByLocalData } from "../utility/gridHelper";
import { SharedDataService } from "../services/sharedDataService";
import { Subscription } from "rxjs";

const customizedAngularComponent = {
  customTooltip: CustomTooltipComponent,
  subscriptionRenderer: SubscriptionRendererComponent,
  serviceTypeRenderer: ServiceTypeRendererComponent,
  statusTooltip: StatusTooltipComponent,
};
export abstract class RequestViewBase extends BaseComponent {
  abstract gridOptions: GridOptions;
  abstract rowData: any;
  abstract initPageSize: number;
  abstract onGridReady(params: GridOptions);
  abstract refreshData();

  public gridApi: GridApi;
  protected gridColumnApi: ColumnApi;
  protected subscription: Subscription;

  userProfile: IUserProfile = null;
  message: string = "";

  constructor(
    protected modalService: ModalService,
    protected apiService: ApiService,
    protected loadingService: LoadingScreenService,
    protected notificationService: ToastrService,
    protected sharedDataService: SharedDataService
  ) {
    super();
    if (ApiService.userProfile) {
      this.userProfile = ApiService.userProfile;
    } else {
      this.subscription = this.sharedDataService.getUserProfile().subscribe((data) => {
        this.userProfile = data;
        console.log(data);
        console.log("in binding subscription");
        try {
          if (data) {
            this.gridApi?.showLoadingOverlay();
            this.refreshData();
          } else {
            this.notificationService.error("user profile returns null, no data was loaded.");
          }
        } catch (error) {
          this.notificationService.error(`unable to get user profile, error: ${error.message}`);
        }
      });
    }
  }

  // common code for ngOnInit()
  InitAgGrid(gridColDefinition: (ColDef | ColGroupDef)[]) {
    // setup ag-grid option for customized tooltip angular component
    // check https://www.ag-grid.com/javascript-grid-components/
    // to see how to register external components to extend ag-grid with angular framework

    this.gridOptions = {
      frameworkComponents: customizedAngularComponent,
      defaultColDef: {
        filter: "agSetColumnFilter",
        resizable: true,
        sortable: true,
      },
      context: this,
      enableCellTextSelection: true,
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
            toolPanelParams: {
              suppressRowGroups: true,
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
              suppressSideButtons: true,
              suppressColumnFilter: true,
              suppressColumnSelectAll: true,
              suppressColumnExpandAll: true,
            },
          },
        ],
        position: "left", // not working in this version of ag-grid
      },
      columnDefs: gridColDefinition,
      overlayLoadingTemplate: '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>',
      paginationPageSize: this.initPageSize,
      getRowHeight: (params) => {
        return this.onGetRowHeight(params);
      },
      // setup ag-grid master/detail option
      // check ag-grid.com/javascript-grid-master-detail/
      // to see how to expand a row to get more detail
      detailCellRendererParams: {
        detailGridOptions: {
          context: this,
          frameworkComponents: customizedAngularComponent,
          rowClass: "ticket-row",
          columnDefs: [
            {
              headerName: "Type-ID",
              field: "DetailsId",
              resizable: true,
              minWidth: 200,
              // overload ag-grid cellRenderer to enable URL
              cellRenderer: (params) => {
                if (!params.data) {
                  return "<span data-test-id=''></span>";
                }
                const id: string = params.data.DetailsId;
                const href = `${params.data.Url}`;
                const type = params.data.Type;
                if (href) {
                  return `<span>${type}</span>-<a href="${href}" rel="noopener noreferrer" target="_blank" data-test-id=${id}>${id}</a>`;
                } else {
                  return `<span>${type}</span>-<span rel="noopener noreferrer" data-test-id=${id}>${id}</span>`;
                }
              },
            },
            {
              headerName: "Status",
              field: "Status",
              resizable: true,
              tooltipField: "Status",
              tooltipComponent: "customTooltip",
              maxWidth: 200,
              cellStyle: (params) => {
                if (params.data.Status === TicketStatus.InComplete) {
                  return { color: "red" };
                } else {
                  return null;
                }
              },
              cellRenderer: (params) => {
                if (!params.data) {
                  return "<span data-test-status=''></span>";
                }
                const id = params.data.DetailsId;
                const status = params.data.Status;
                if (params.data.Type === DetailsDataType.CISJob || params.data.Type === DetailsDataType.CISTask) {
                  return "<span></span>";
                }
                const editIcon = "!";
                if (status === TicketStatus.Completed || id === -1) {
                  return `<span data-test-status=${status}>${status}</span>`;
                }
                if (status === TicketStatus.InComplete) {
                  return `${editIcon} <a href="javascript:void(0)" data-test-status=${status}>${status}</a>`;
                }
                return `<a href="javascript:void(0)" data-test-status=${status}>${status}</a>`;
              },
              onCellClicked: (params) => {
                if (params.data.Type === DetailsDataType.CISJob || params.data.Type === DetailsDataType.CISTask) {
                  return;
                }
                if (params.data.Status === TicketStatus.Completed) {
                  return;
                }
                const id = params.data.DetailsId;
                const region = params.data.Region;
                if (!id || !region) {
                  return;
                }
                const isRequestArcihved = params.data.Status === TicketStatus.Cancelled || params.data.Status === TicketStatus.Completed;
                this.loadingService.setLoading(true);
                this.apiService.getWorkItemById(id, region).subscribe(
                  (rep) => {
                    this.modalService
                      .ticketCommentsModal(rep, id, region, isRequestArcihved)
                      .then(() => {})
                      .catch((err) => {
                        this.message = err.message;
                      });
                  },
                  (err) => {
                    this.message = err.message;
                    this.loadingService.setLoading(false);
                  },
                  () => {
                    this.loadingService.setLoading(false);
                  }
                );
              },
            },
            {
              headerName: "Created Time",
              field: "CreatedTime",
              resizable: true,
              maxWidth: 200,
              cellRenderer: (params) => {
                if (!params.data) {
                  return "";
                }
                console.log(params.data);
                return params.data.CreatedTime;
              },
              filter: "agDateColumnFilter",
              filterParams: {
                comparator: (filterLocalDateAtMidnight, cellValue) => agDateColumnFilter(filterLocalDateAtMidnight, cellValue),
              },
            },
            {
              headerName: "Notes",
              field: "Notes",
              resizable: true,
              maxWidth: 200,
            },
          ],
          onGridReady(params) {
            // make detailGrid height automatically
            params.api.setDomLayout("autoHeight");
            params.api.sizeColumnsToFit();
          },
          onFirstDataRendered(params) {
            params.api.sizeColumnsToFit();
            window.setTimeout(() => {
              const colIds = params.columnApi.getAllColumns().map((c) => c.getColId());
              params.columnApi.autoSizeColumns(colIds);
            }, 50);
          },
        },
        getDetailRowData: async (params) => {
          const ticketsPromise = this.apiService.getTickets(params.data.RequestId).toPromise();
          const cisJobPromise = this.apiService.getCisJobByRequestId(params.data.RequestId).toPromise();

          params.data.requestData = [];
          const tickets = await ticketsPromise;
          if (tickets) {
            tickets.forEach((ticket: Ticket) => {
              params.data.requestData.push({
                Url: ticket.Url,
                DetailsId: ticket.TicketId,
                Status: ticket.State,
                Type: DetailsDataType.Ticket,
                CreatedTime: ticket.CreatedTime,
                Region: params.data.Region,
                Notes: ticket.Notes,
              });
            });
          }

          const cisJob = await cisJobPromise;
          if (cisJob) {
            if (cisJob.TaskId && cisJob.TaskId !== "*") {
              // it's a cis task
              params.data.requestData.push({
                Url: GenerateCISTaskUrl(cisJob),
                DetailsId: `${cisJob.JobId};${cisJob.TaskId}`,
                Status: "", // For cis job/task, we ne longer track its status, so we do not display it.
                Type: DetailsDataType.CISTask,
                CreatedTime: cisJob.CreatedTime,
                LastUpdatedTime: cisJob.LastUpdatedTime,
                Region: params.data.Region,
              });
            } else {
              // it's a cis job
              params.data.requestData.push({
                Url: GenerateCISJobUrl(cisJob),
                DetailsId: cisJob.JobId,
                Status: "", // For cis job/task, we ne longer track its status, so we do not display it.
                Type: DetailsDataType.CISJob,
                CreatedTime: cisJob.CreatedTime,
                LastUpdatedTime: cisJob.LastUpdatedTime,
                Region: params.data.Region,
              });
            }
          }

          params.node.setRowHeight(null); // set null to enable auto adjustment
          this.gridApi.onRowHeightChanged(); // this triggers getRowHeight silently

          params.data.requestData.forEach((element: any) => {
            element.CreatedTime = PreprocessDateTime(element.CreatedTime);
          });

          params.successCallback(params.data.requestData);
        },
      },
      onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
        window.setTimeout(() => {
          const colIds = params.columnApi.getAllColumns().map((c) => c.getColId());
          params.columnApi.autoSizeColumns(colIds);
        }, 50);
      },
      immutableData: true,
      getRowNodeId(data) {
        return data.RequestId;
      },
      tooltipShowDelay: 0,
    };
  }

  SetAgGridContext(params: GridOptions) {
    const sort = {
      state: [
        {
          colId: "LastUpdatedTime",
          sort: "desc",
        },
      ],
    };

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridColumnApi.applyColumnState(sort);
    this.gridApi.showLoadingOverlay();
  }
  getAZMappings(subscriptionId: string, region: string, cloudType: string) {
    this.modalService
      .azMappingsModal(region, subscriptionId, cloudType)
      .then(() => {})
      .catch((err) => {
        this.message = err.message;
      });
  }

  LoadData() {
    // block the UI with loading icons
    // remove the blocked UI in the
    if (ApiService.userProfile) {
      this.apiService
        .getUserProfile()
        .then((response) => {
          this.userProfile = response;
          if (response) {
            this.gridApi?.showLoadingOverlay();
            this.refreshData();
          } else {
            this.notificationService.error("user profile returns null, no data was loaded.");
          }
        })
        .catch((error) => {
          this.notificationService.error(`unable to get user profile, error: ${error.message}`);
        });
    }
  }

  /*
   * To enable this function, define (rowGroupOpened)="onRowGroupOpened($event)"
   * in <ag-grid-angular></ag-grid-angular>
   * this event handler is for resolving the last row expanding issue
   */
  onRowGroupOpened(params: any) {
    let pageSize = this.gridApi.paginationGetPageSize();
    if (params.node.expanded) {
      if (pageSize === this.initPageSize && params.node.rowIndex === pageSize - 1) {
        // this.gridApi.paginationSetPageSize(++pageSize);
        // this.gridApi.ensureIndexVisible(params.node.rowIndex + 1);// makes the scroll down a little bit
        this.gridApi.paginationGoToNextPage();
      }
    } else {
      if (pageSize > this.initPageSize) {
        this.gridApi.paginationSetPageSize(--pageSize);
      }
    }
  }

  calcDetailRowsHeight(rowHeight, numOfRows): number {
    const defaultRowHeight = rowHeight; // 28; //params.node.rowHeight;
    const defaultColumnHeight = defaultRowHeight * 1.2;
    const spareSpace = defaultRowHeight * 0.5;
    const detailRowsHeight = Math.max(2, numOfRows) * defaultRowHeight;
    return defaultRowHeight + detailRowsHeight + defaultColumnHeight + spareSpace;
  }

  /*
     * To enable this agGrid callback, define [getRowHeight]="onGetRowHeight"
       in <ag-grid-angular></ag-grid-angular> @angular component file
       or define getRowHeight: params => {} in this.gridOptions = {}
       don't know why the way in components file didn't behave the same as the way in gridOptions,
     */
  onGetRowHeight(params) {
    const defaultRowHeight = params.node.rowHeight;
    if (params.data.requestData) {
      return this.calcDetailRowsHeight(defaultRowHeight, params.data.requestData.length);
    } else {
      return defaultRowHeight; // default row hight
    }
  }

  onFilterChanged(event, key: string) {
    cacheFilterStatsLocal(event, key);
  }

  setDefaultFilter(key: string, enableAutoSizeColumns: boolean = true) {
    setupFilterByLocalData(this.gridApi, this.gridColumnApi, key, enableAutoSizeColumns);
  }
}

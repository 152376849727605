import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { GridOptions } from "ag-grid-community";
import { environment } from "src/environments/environment";
import { Title } from "@angular/platform-browser";
import { ApiService } from "../../services";
import {
  ISubRequest,
  TicketPriorityEnum,
  IUserProfile,
  ISubRequestUpdateData,
  TicketStatus,
  RequestStatus,
  DeploymentScope,
  RequestOperation,
  IParentRequest,
  TicketChannel,
  RequestServiceType,
} from "../../npr-request.model";
import { PreprocessDateTime } from "../../utility/view-field-mapping";
import { BaseComponent } from "../../shared/base.component";
import { ModalService } from "../../shared/modal.service";
import { AuthService } from "../../auth/auth.service";
import { LoadingScreenService } from "../../shared/loading-screen.service";
import {
  GenerateCISJobUrl,
  GenerateCISTaskUrl,
  GetDependencyLinks,
  getRequestStatusDescription,
  OnColumnMoved,
  setColumnState,
} from "../../utility/common-helper";
import { Approval, Ticket, CisJob, OperationLog, Discussion } from "../../generated-models";
import { extendDefaultOptions } from "../../utility/gridHelper";
import { CustomTooltipComponent } from "../../shared/auxillary-components/custom-tooltip.component";
import { ServiceTypeRendererComponent } from "../../shared/az-mapping-modal/service-type-renderer.component";
import { ApproveRequestService } from "../../services/approveRequest.service";
import { IcmNotification } from "../../generated-models/IcmNotification";
import { OperationLogColumnDefinition } from "./operation-log-columns";

@Component({
  templateUrl: "./request-detail.component.html",
  styleUrls: ["../../styles.scss", "./request-detail.scss"],
})
export class RequestDetailComponent extends BaseComponent implements OnInit {
  parentReqId: string;
  subReqId: number;
  ticketId: string;
  region: string;
  subReq = {} as ISubRequest;
  tickets = [] as Ticket[];
  icmNotifications = [] as IcmNotification[];
  cisJob: CisJob = null;
  discussions: Discussion[] = [];
  // If the CISJob is actually corresponded to a cis task.
  isCisTask = false;
  approvals: Approval[] = [];
  contacts: { [key: string]: string };
  priorities: string[] = ["1", "2", "3"];
  previousApprovals = new Map<string, Approval[]>();
  selectedPriority = "3";
  haveQuota = false;
  rdQuotaContact = "cm24x7@microsoft.com";
  errorMessage = "";
  canUpdatePriority = false;
  canUpdateUtilizeDate = false;
  canBeApproved = false;
  isNotFound = false;
  isForbidden = false;
  isError = false;
  isLoading = true;
  parentRequestUrl = "";
  isAnyTicketIncomplete = false;
  isAdmin = false;
  isSubmitter = false;
  canSkipDenpendencies = false;
  disableCancel = false;
  cancelTooltip = "";
  parentRequest = {} as IParentRequest;
  icmTicketChannel = TicketChannel.ICM;

  private oldPriority = 0;
  private oldUtilizeDate: Date;
  private userProfile: IUserProfile = null;
  private isRequestArchived = false;
  public newDiscussion = "";
  public hasAppServiceLinuxQuota = false;
  // Gird config
  gridOptions: GridOptions;
  rowData: OperationLog[] = [];
  columnDef = OperationLogColumnDefinition;
  initPageSize = 15;

  constructor(
    public auth: AuthService,
    private activatedRoute: ActivatedRoute,
    protected apiService: ApiService,
    protected modalService: ModalService,
    protected loadingService: LoadingScreenService,
    protected notificationService: ToastrService,
    protected approveRequestService: ApproveRequestService,
    private title: Title
  ) {
    super();
  }

  onGridReady(params: GridOptions): void {
    this.gridOptions.columnApi.autoSizeAllColumns();
    setColumnState(params, "subRequestDetailColumnState");
  }

  async ngOnInit(): Promise<void> {
    // Set AG grid options
    this.gridOptions = extendDefaultOptions({
      columnDefs: this.columnDef,
      context: this, // passed context for customized component callback
      frameworkComponents: {
        // register angular component for customized column header
        // https://www.ag-grid.com/javascript-grid-header-rendering/#example-header-component
        customTooltip: CustomTooltipComponent,
        serviceTypeRenderer: ServiceTypeRendererComponent,
      },
      isRowSelectable: () => {
        return true;
      },
      animateRows: true,
      paginationPageSize: this.initPageSize,
      onColumnMoved: (params) => OnColumnMoved(params, "subRequestDetailColumnState"),
    });

    this.loadingService.setLoading(true);
    const showComment = this.activatedRoute.snapshot.queryParams.showComment;

    // This observable will not be resolved, so can not use toPromise
    this.activatedRoute.params.subscribe((queryParams) => {
      this.parentReqId = queryParams.parentReqId;
      this.subReqId = queryParams.subReqId;
      this.region = queryParams.region;
      this.ticketId = queryParams.ticketId;
      this.title.setTitle(`Request ${this.parentReqId}-${this.subReqId} - Region Access and Quota`);
    });

    // Get parent request
    this.parentRequest = await this.apiService.getParentRequest(this.parentReqId).toPromise();

    // Get sub request
    const subRequest = await this.apiService.GetSubRequest(this.parentReqId, this.subReqId);
    if (!subRequest) {
      this.isNotFound = true;
      this.isLoading = false;
      this.loadingService.setLoading(false);
      return;
    }

    this.subReq = subRequest;
    this.hasAppServiceLinuxQuota =
      this.subReq.ServiceParams == null
        ? false
        : this.subReq.RequestServiceType === RequestServiceType.AppService &&
          "LinuxVmQuota" in this.subReq.ServiceParams &&
          (this.subReq.ServiceParams["LinuxVmQuota"] as number) > 0;
    this.isRequestArchived = this.subReq.Status === RequestStatus.Completed || this.subReq.Status === RequestStatus.Cancelled;
    this.isLoading = false;
    this.subReq.UtilizeDate = this.subReq.UtilizeDate && new Date(this.subReq.UtilizeDate);
    this.oldUtilizeDate = this.subReq.UtilizeDate;
    this.haveQuota = !isNaN(Number(this.subReq.Quota)) && Number(this.subReq.Quota) > 0;
    this.oldPriority = this.subReq.Priority;
    this.subReq.RequestDisplayServiceType = ApiService.fetchServiceTypeName(this.subReq.RequestServiceType, this.subReq.SKU);
    if (this.subReq.CreatedTime) {
      this.subReq.CreatedTime = PreprocessDateTime(subRequest.CreatedTime);
    }
    if (this.subReq.CompletedTime) {
      this.subReq.CompletedTime = PreprocessDateTime(subRequest.CompletedTime);
    }
    this.selectedPriority = this.subReq.Priority === 0 ? "3" : `${this.subReq.Priority}`;

    // Get user profile
    this.userProfile = await this.apiService.getUserProfile();
    const username = (await this.auth.getUser())?.username;
    this.canUpdatePriority = this.subReq.Status === RequestStatus.Created && this.userProfile.IsApprover;
    this.canUpdateUtilizeDate =
      this.subReq.Status === RequestStatus.Created && (username === this.subReq.Submitter || this.userProfile.IsApprover);
    this.canBeApproved = this.subReq.Status === RequestStatus.Created && this.userProfile.IsApprover;
    this.canSkipDenpendencies = this.subReq.Status == RequestStatus.WaitingDependency && this.userProfile.IsAdmin;
    if (this.userProfile?.IsAdmin) {
      this.isAdmin = true;
    }

    // Check submitter
    if (this.subReq.Submitter === this.userProfile.Email) {
      this.isSubmitter = true;
    }

    this.disableCancel = this.subReq.Status === RequestStatus.Completed || this.subReq.Status === RequestStatus.Cancelled;
    if (this.disableCancel === true) {
      this.cancelTooltip = "Unable to cancel a completed request";
    }

    // Get approvals information
    const approvals = await this.apiService.getSubRequestApprovals(this.parentReqId, this.subReqId).toPromise();
    if (approvals) {
      this.approvals = approvals.Approvals;
      this.approvals.forEach((approval) => {
        if (approval && approval.At) {
          approval.At = new Date(PreprocessDateTime(approval.At));
        }
      });
      if (this.subReq.Status === RequestStatus.Completed) {
        this.approvals = this.approvals.filter((approval) => approval?.At);
      } else if (this.subReq.Status === RequestStatus.Rejected) {
        // For rejected request, only display the reject approval.
        this.approvals = this.approvals.filter((approval) => approval.Type === "Reject");
      }

      // Get previous approvals
      if (approvals.PrevApprovals) {
        approvals.PrevApprovals.forEach((app: Approval) => {
          let approvals = [];
          if (this.previousApprovals.has(app.Type)) {
            approvals = this.previousApprovals.get(app.Type);
          }
          const iAt = app.By.indexOf("@");
          if (iAt > 0) {
            app.By = app.By.substring(0, iAt);
          }
          approvals.push(app);
          this.previousApprovals.set(app.Type, approvals);
        });
      }

      // Get contacts
      this.contacts = approvals.Contacts;
    }

    // Get rdQuota contact
    if (this.parentRequest && this.parentRequest.Region.search("China") != -1) {
      this.rdQuotaContact = "mooncake_customerops@microsoft.com";
    }

    // Hide Unknown RingLevel
    if (this.subReq.RingLevel === "Unknown") {
      this.subReq.RingLevel = "";
    }

    if (this.subReq.Status === RequestStatus.InProgress && this.subReq.FulfillChannel === "RDQuota") {
      this.subReq.Status = "Awaiting RDQuota";
    }

    // Get Discussions
    this.getDiscussions();

    // Get IcmNotification
    try {
      this.icmNotifications = await this.apiService
        .getIcmNotifications(this.subReq.ParentRequestId, this.subReq.RequestServiceType)
        .toPromise();
    } catch (err: unknown) {
      this.icmNotifications = [];
      this.notificationService.error(err as string);
    }

    this.gridOptions.getRowHeight = function (params) {
      if (params.data.Details == null) {
        return 30;
      }
      return params.data.Details.split("\n").length * 30;
    };

    // TODO(lingc): The code in the try block should not use "await". It's anti-design of the UI framework.
    // Notice that, if not use "await", the error handling code in the catch block can never be reached. we should update the error handling as well.
    try {
      // Get operation history
      this.rowData = await this.apiService.getSubRequestsOperationHistory(this.parentReqId, this.subReqId).toPromise();

      // Get tickets
      this.tickets = await this.apiService.getTickets(`${this.parentReqId}-${this.subReqId}`).toPromise();
      if (this.tickets) {
        this.tickets.forEach((ticket: Ticket) => {
          if (ticket) {
            if (ticket.CreatedTime) {
              ticket.CreatedTime = new Date(PreprocessDateTime(ticket.CreatedTime));
            }
            if (this.IsTicketIncompleted(ticket)) {
              this.isAnyTicketIncomplete = true;
            }
          }
        });
      }

      if (showComment === "true") {
        console.log("redirect to ticket comment modal");
        this.showTicketComment();
      }

      // Get CIS job
      this.cisJob = await this.apiService.getCisJobByRequestId(`${this.parentReqId}-${this.subReqId}`).toPromise();
      if (this.cisJob) {
        this.isCisTask = this.cisJob.TaskId && this.cisJob.TaskId !== "*";
        if (this.isCisTask) {
          this.cisJob.JobLink = GenerateCISTaskUrl(this.cisJob);
        } else {
          this.cisJob.JobLink = GenerateCISJobUrl(this.cisJob);
        }

        if (this.cisJob.CreatedTime && this.cisJob.LastUpdatedTime) {
          this.cisJob.LastUpdatedTime = new Date(PreprocessDateTime(this.cisJob.LastUpdatedTime));
          this.cisJob.CreatedTime = new Date(PreprocessDateTime(this.cisJob.CreatedTime));
        }
      }
    } catch (errorResponse) {
      this.isLoading = false;
      this.isError = true;
      this.errorMessage = errorResponse.message || errorResponse;
    }

    this.parentRequestUrl = `${window.location.origin}${environment.siteRootPath}/requests/${this.parentReqId}`;
    this.loadingService.setLoading(false);
  }

  getSubscriptionDetails(subscriptionId: string): void {
    this.modalService.subscriptionDetailsModal("Subscription Details", subscriptionId).catch((err) => {
      console.error(err);
    });
  }

  GetPriorityString(priority: number): string {
    let priorityNumber = priority;
    priorityNumber = priorityNumber === 0 ? 3 : priorityNumber;
    return `${priorityNumber} - ${TicketPriorityEnum[priorityNumber]}`;
  }

  GetDependencyHtmls(dependencies: string): string {
    return GetDependencyLinks(dependencies);
  }

  UpdatePriority(value: string): void {
    this.subReq.Priority = Number(value);
  }

  IsTicketIncompleted(ticket: Ticket): boolean {
    return ticket.State === TicketStatus.ActionRequired;
  }

  SubmitChanges(): void {
    if (this.SubrequestUpdated()) {
      // Update the subrequest to the backend. This will update the changes on Priority/Utilize Date.
      if (this.subReq.Status !== RequestStatus.Created) {
        this.modalService.informationModal("Requests cannot be updated after approval.");
        return;
      }

      this.updateRequest();
    }
  }

  updateRequest(comment = ""): void {
    const updateData = {
      Quota: Number(this.subReq.Quota),
      Priority: Number(this.subReq.Priority),
      UtilizeDate: this.subReq.UtilizeDate,
      Comment: comment,
      ServiceParamsString: JSON.stringify(this.subReq.ServiceParams),
    } as ISubRequestUpdateData;
    this.loadingService.setLoading(true);
    this.apiService.updateSubRequest(this.subReq.ParentRequestId, this.subReq.SubRequestId, updateData).subscribe(
      () => {
        const msg = `Successfully updated subrequest ${this.parentReqId}-${this.subReq.SubRequestId}`;
        this.notificationService.info(msg);
        this.loadingService.setLoading(false);
      },
      (error: unknown) => {
        const message = `Failed to update subrequest #${this.parentReqId}-${this.subReq.SubRequestId}, error: ${error}.`;
        this.notificationService.error(message);
        this.loadingService.setLoading(false);
      }
    );
  }

  onShowTicketCommentClicked(ticket: Ticket): void {
    if (ticket.Channel === this.icmTicketChannel) {
      return;
    }
    const id = ticket.TicketId.toString();
    const region = this.subReq.Region;
    this.getTicketComment(id, region, this.isRequestArchived);
  }

  getTicketComment(id: string, region: string, isRequestArchived: boolean): void {
    if (!id || !region) {
      return;
    }
    this.loadingService.setLoading(true);
    this.apiService.getWorkItemById(id, region).subscribe(
      (resp) => {
        this.loadingService.setLoading(false);
        this.modalService
          .ticketCommentsModal(resp, id, region, isRequestArchived)
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {
            this.loadingService.setLoading(false);
          });
      },
      (err: unknown) => {
        const message = `Failed to get ticket comment for subrequest ${this.parentReqId}-${this.subReq.SubRequestId}, error: ${err}.`;
        this.modalService.informationModal(message);
        this.loadingService.setLoading(false);
      }
    );
  }
  IsUpdateButtonDisabled(): boolean {
    return this.isNotFound || this.isForbidden || this.isError || this.isLoading || !this.SubrequestUpdated();
  }
  IsRetryable(): boolean {
    return this.parentRequest.Status !== RequestStatus.Created;
  }
  SubrequestUpdated(): boolean {
    return this.oldPriority !== this.subReq.Priority || this.oldUtilizeDate?.getTime() !== this.subReq.UtilizeDate?.getTime();
  }
  HasNewDiscussion(): boolean {
    // this.newDiscussion should never be null or undefined.
    return this.newDiscussion.length > 0;
  }
  requestStatusName(status: string): string {
    return getRequestStatusDescription(status)?.name || status;
  }
  requestStatusDescription(status: string): string {
    return getRequestStatusDescription(status)?.description;
  }

  async ApproveRequest(): Promise<void> {
    const refresh = await this.approveRequestService.approveRequest([this.subReq]);
    if (refresh) {
      this.ngOnInit();
    }
  }

  getDiscussions(): void {
    this.apiService.getDiscussions(this.parentReqId, this.subReqId).subscribe(
      (res) => {
        this.discussions = res;
      },
      (error: unknown) => {
        const message = `Failed to get discussion list for subrequest ${this.parentReqId}-${this.subReq.SubRequestId}, error: ${error}.`;
        this.notificationService.error(message);
      }
    );
  }

  saveNewDiscussion(): void {
    const discussion = { Content: this.newDiscussion, ParentRequestId: this.parentReqId, SubRequestId: this.subReqId } as Discussion;
    this.apiService.appendDiscussion(this.parentReqId, this.subReqId, discussion).subscribe(
      () => {
        this.newDiscussion = "";
        this.notificationService.info("Successfully saved your new discussion.");
        this.getDiscussions();
      },
      (error: unknown) => {
        const message = `Failed to update the new discussion for subrequest ${this.parentReqId}-${this.subReq.SubRequestId}, error: ${error}.`;
        this.notificationService.error(message);
      }
    );
  }

  formatDateTime(date: Date): string {
    return PreprocessDateTime(date);
  }

  get deploymentScope(): typeof DeploymentScope {
    return DeploymentScope;
  }

  showTicketComment(): void {
    if (this.ticketId && this.region) {
      this.getTicketComment(this.ticketId, this.region, this.isRequestArchived);
    } else if (this.isAnyTicketIncomplete) {
      // Show first incomplete ticket's comments
      if (!this.ticketId) {
        const inCompleteTicket = this.tickets?.find((ticket) => ticket.State === TicketStatus.ActionRequired);
        if (inCompleteTicket) {
          this.ticketId = inCompleteTicket.TicketId.toString();
        }
      }
      this.region = this.region || this.subReq.Region;
      this.getTicketComment(this.ticketId, this.region, this.isRequestArchived);
    }
  }

  async retry(): Promise<void> {
    const needRefresh: boolean = await this.modalService.activeSubRequestModal("Retry Ruquest", RequestOperation.Retry, [this.subReq]);
    if (needRefresh) {
      this.ngOnInit();
    }
  }

  async skipDependencies(): Promise<void> {
    const needRefresh: boolean = await this.modalService.activeSubRequestModal(
      "Skip Request Dependencies",
      RequestOperation.SkipDependencies,
      [this.subReq]
    );
    if (needRefresh) {
      this.ngOnInit();
    }
  }

  async cancelSubRequest(): Promise<void> {
    const needRefresh: boolean = await this.modalService.activeSubRequestModal("Cancel Ruquest", RequestOperation.Cancel, [this.subReq]);
    if (needRefresh) {
      this.ngOnInit();
    }
  }
}
